<template>
  <v-app>
    <v-container class="my-6 pa-0" fluid>
      <v-card class="py-0">
        <div class="card-header-padding pb-0">
          <v-row class="mb-0">
            <v-col lg="8" sm="8" md="8" class="pb-0">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Үнэлгээ 2 -
                  <span
                    v-if="selectedProgram"
                    @click="_print2"
                    class="blue--text"
                    >{{ selectedProgram["STUDENT_GROUP_NAME"] }}
                  </span>
                </h5>
                <p class="text-typo">
                  Нийт xичээлийн тоо:
                  <span class="blue--text" v-if="filteredLessons">{{
                    filteredLessons.length
                  }}</span>
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="card-header-padding pt-0">
          <!-- <v-progress-linear
            v-if="loading"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear> -->
          <v-data-table
            class="scoreTables"
            v-if="lessons"
            hide-default-footer
            hide-default-header
            :items-per-page="-1"
            :items="filteredLessons"
          >
            <template slot="header">
              <tr style="font-size: 13px">
                <th class="text-start font-weight-normal">No</th>
                <th class="text-start font-weight-normal">Ээлжит хичээл</th>
                <th class="text-start font-weight-normal">Багш</th>
                <th class="text-start font-weight-normal">Төрөл</th>
                <th class="text-start font-weight-normal">С.Т</th>
                <th class="pl-0">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text-start font-weight-normal"
                      style="
                        border-bottom: 2px solid #dddddd;
                        padding-left: 5px;
                      "
                      >Баталгаажсан</v-col
                    >
                    <v-col
                      class="text-center font-weight-normal"
                      cols="6"
                      style="border-right: 2px solid #dddddd; padding-left: 5px"
                      >Хувь</v-col
                    >
                    <v-col
                      class="text-center font-weight-normal"
                      cols="6"
                      style="padding-left: 5px"
                      >Тоо</v-col
                    >
                  </v-row>
                </th>
                <th class="text-center font-weight-normal">Хянагдаж байгаа</th>
                <th class="text-center font-weight-normal">Цуцлагдсан</th>
                <th class="text-center font-weight-normal">Бүртгэсэн</th>
                <th class="text-center font-weight-normal">
                  Үнэлгээний дундаж
                </th>
                <th>Үйлдэл</th>
              </tr>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td @click="_print(item)">{{ item.index }}</td>
                <td class="text-start px-1" style="white-space: nowrap">
                  {{ item.courseInfo.COURSE_NAME }}
                </td>
                <td class="px-1">
                  <template>
                    <small
                      class="blue--text"
                      v-for="(teacher, teacherIndex) in item.byTeachers"
                      :key="teacherIndex"
                      ><span v-if="teacherIndex > 0">, </span
                      >{{ teacher.teacherDisplayName }}</small
                    >
                  </template>
                </td>
                <td
                  v-if="item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'"
                  class="px-1"
                >
                  <small class="px-1 red--text"> Сонгон</small>
                </td>
                <td v-else><small>Заавал</small></td>
                <td
                  style="background-color: #ffebee"
                  v-if="item && item.numberOfStudents <= 1"
                  class="text-center blue--text px-2"
                >
                  {{ item.numberOfStudents }}
                </td>
                <td
                  v-else-if="item && item.numberOfStudents > 1"
                  class="text-center blue--text px-2"
                >
                  {{ item.numberOfStudents }}
                </td>
                <td v-else>-</td>
                <td class="pl-0">
                  <v-row no-gutters style="height: 100% !important">
                    <v-col
                      cols="6"
                      class="pt-1 text-center"
                      style="border-right: 2px solid #dddddd; padding-left: 5px"
                    >
                      <span
                        style="color: green"
                        v-if="_getConfirmationProcent(item) == 100"
                        color="green"
                        >100%</span
                      >
                      <span v-else-if="_getConfirmationProcent(item) == 0">
                        0%</span
                      >
                      <span v-else>
                        {{ _getConfirmationProcent(item).toFixed(1) }}%</span
                      >
                    </v-col>

                    <v-col
                      cols="6"
                      class="pt-1 text-center"
                      style="padding-left: 5px; color: green"
                    >
                      {{
                        item.year1UnelgeeScores
                          ? item.year1UnelgeeScores.approvedNumber
                          : 0
                      }}</v-col
                    >
                  </v-row>
                </td>
                <td style="color: #db9943" class="text-center">
                  {{
                    item.year1UnelgeeScores
                      ? item.year1UnelgeeScores.inProgressNumber
                      : 0
                  }}
                </td>
                <td style="color: red" class="text-center">
                  {{
                    item.year1UnelgeeScores
                      ? item.year1UnelgeeScores.rejectedNumber
                      : 0
                  }}
                </td>
                <td style="color: purple" class="text-center">
                  {{
                    item.year1UnelgeeScores
                      ? item.year1UnelgeeScores.sentNumber
                      : 0
                  }}
                </td>
                <td class="text-center">
                  {{
                    item.year1UnelgeeScores
                      ? item.year1UnelgeeScores.scoreAverage > 0
                        ? item.year1UnelgeeScores.scoreAverage
                        : 0
                      : 0
                  }}%
                </td>
                <td>
                  <v-progress-linear
                    v-if="item.loading2 == true"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <v-btn
                    v-else
                    dark
                    elevation="0"
                    x-small
                    class="bg-gradient-danger text-capitalize mx-2"
                    @click="_doUnelgee(item)"
                  >
                    баталгаaжуулах
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-progress-linear
            v-else-if="loading == false"
            indeterminate
            color="red"
          ></v-progress-linear>
        </div>
      </v-card>
    </v-container>
    <v-dialog
      v-model="unelgeeDialog"
      height="500"
      width="1400"
      v-if="currentSelectedLesson && renderComponent"
      persistent
      scrollable
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0">
          <v-row class="pt-4">
            <v-col lg="6" md="6" sm="6" cols="6" class="mt-1">
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                Үнэлгээ 2 - {{ userData.school.currentYear }}
                <span
                  class="blue--text ml-4"
                  v-if="currentSelectedLesson"
                  @click="_print3"
                >
                  {{ currentSelectedLesson.courseInfo.COURSE_NAME }}/
                  <span
                    v-if="
                      currentSelectedLesson.classGroups &&
                      currentSelectedLesson.classGroups.length > 0
                    "
                  >
                    {{
                      currentSelectedLesson.classGroups[0].classGroupFullName
                    }}
                  </span>
                </span>
              </h5>
            </v-col>
            <v-col lg="3" md="3" sm="3" cols="3" class="mt-n5">
              <v-select
                v-model="selectedGradingMethod"
                label="Бүлэг сэдвийн үнэлгээ (Үнэлгээ-I)"
                item-text="name"
                item-value="value"
                :items="gradingMethods"
              >
              </v-select>
            </v-col>
            <v-col class="justify-self-end" cols="auto">
              <v-switch
                disabled
                class="ml-2 my-0 py-0"
                color="red"
                v-model="simpleCalculationModel"
                :label="
                  simpleCalculationModel
                    ? 'Энгийн (' + simpleCalculationModel + ')'
                    : 'Ахисан (' + simpleCalculationModel + ')'
                "
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
        <ScoreInputUnelgee2DialogManager
          class="pt-0"
          v-if="allScores"
          v-bind="$attrs"
          :students="students"
          :allScores="allScores"
          :simpleCalculationModel="simpleCalculationModel"
          :lesson="currentSelectedLesson"
          :selectedGradingMethod="selectedGradingMethod"
          :unelgee1Scores="unelgee1Scores"
        >
        </ScoreInputUnelgee2DialogManager>
        <v-card-text
          class="mx-10 py-10 mt-4 px-4 text-center"
          style="width: 80%; overflow: hidden"
          v-if="savingUnelgee"
        >
          <p style="display: block: !important;" class="red--text">
            Дүн хадгалж байна. Түр хүлээнэ үү!
          </p>
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="mx-6 py-10 mt-4" v-else>
          <v-spacer></v-spacer>
          <v-btn color="grey" class="white--text" @click="_closeUnelgeeDialog">
            Цуцлах
          </v-btn>
          <v-btn
            @click="_saveUnelgee2"
            class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
          >
            Хадгалах
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ScoreInputUnelgee2DialogManager from "./ScoreInputUnelgee2DialogManager";
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    ScoreInputUnelgee2DialogManager,
  },
  data: () => ({
    currentSelectedSemester: 2,
    coursesAdditional: null,
    school: null,
    showMyClassLesson: true,
    students: null,
    selectedProgram: null,
    teachers: null,
    selectedType: { name: "Заавал судлаx", value: 1, kValue: "MANDATORY" },
    lessons: null,
    loading: false,
    currentSelectedLesson: null,
    renderComponent: true,
    unelgeeDialog: false,
    savingUnelgee: false,
    simpleCalculationModel: true,
    allScores: null,
    selectedGradingMethod: 2,
    gradingMethods: [
      {
        name: "Дундаж дүнгээр",
        value: 0,
      },
      {
        name: "Хамгийн өндөр шалгалтын дүнгээр",
        value: 1,
      },
      {
        name: "Сүүлийн шалгалтын дүнгээр",
        value: 2,
      },
    ],
    unelgee1Scores: null,
  }),

  props: {
    zClassGroupRefPath: {
      type: String,
    },
    refresh: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    // filteredLessons() {
    //   var list = [];
    //   var counter = 0;
    //   if (this.lessons) {
    //     for (const item of this.lessons) {
    //       counter++;
    //       item.courseIndex = counter;
    //       list.push(item);
    //     }
    //     return list;
    //   } else {
    //     return [];
    //   }
    // },
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          if (ll.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE") {
            var parentLL = null;
            const cc = list.find(
              (lesson) =>
                lesson.isCollected &&
                lesson.courseInfo.COURSE_ID &&
                lesson.courseInfo.COURSE_ID == ll.courseInfo.COURSE_ID
            );
            if (cc == undefined) {
              counter++;
              parentLL = ll;
              parentLL.index = counter;
              parentLL.uniqueId = ll.id + counter;
              parentLL.name3 = counter + ". " + ll.name2;
              list.push(parentLL);
            } else {
              if (cc.subLessons == undefined) cc.subLessons = [];
              cc.subLessons.push(ll);
            }
          } else {
            counter++;
            ll.index = counter;
            ll.uniqueId = ll.id + counter;
            ll.name3 = counter + ". " + ll.name2;
            list.push(ll);
          }
        }
      }
      return list;
    },
    // filteredLessons() {
    //   var list = [];
    //   var counter = 0;
    //   if (this.courses) {
    //     for (var course of this.courses) {
    //       if (this.showMyClassLesson)
    //         if (
    //           course &&
    //           course.byTeachers &&
    //           course.byTeachers.length > 0
    //         )
    //           if (
    //             course.esisLessonType &&
    //             course.esisLessonType.esisLessonTypeId > 1 &&
    //             course.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
    //           ) {
    //             var parentLL = null;
    //             const cc = list.find(
    //               (lesson) =>
    //                 lesson.isCollected &&
    //                 lesson.courseInfo.COURSE_ID &&
    //                 lesson.courseInfo.COURSE_ID ==
    //                   course.courseInfo.COURSE_ID
    //             );
    //             if (cc == undefined) {
    //               counter++;
    //               parentLL = course;
    //               parentLL.index = counter;
    //               parentLL.uniqueId =
    //                 course.id + counter;
    //               parentLL.name3 =
    //                 counter + ". " + course.name2;
    //               parentLL.subLessons = [course];
    //               console.log(course);
    //               parentLL.isCollected = true;
    //               list.push(parentLL);
    //             } else {
    //               cc.subLessons.push(course);
    //             }
    //           } else {
    //             counter++;
    //             course.index = counter;
    //             course.uniqueId = course.id + counter;
    //             course.name3 =
    //               counter + ". " + course.name2;
    //             list.push(course);
    //           }
    //     }
    //   }
    //   return list;
    // },
  },
  async created() {
    if (!this.school) {
      this.school = this.userData.school;
    }
    if (this.refresh) {
      location.reload();
    }
    await fb.db
      .doc(this.zClassGroupRefPath)
      .get()
      .then((doc) => {
        let prog = doc.data();
        prog.ref = doc.ref;
        prog.id = doc.id;
        this.selectedProgram = prog;
        this.readPlan();
      });
  },
  methods: {
    _getConfirmationProcent(item) {
      var xxx = item.year1UnelgeeScores
        ? item.year1UnelgeeScores.approvedNumber
        : 0;
      if (item.numberOfStudents > 0) return (xxx / item.numberOfStudents) * 100;
      return 0;
    },
    _print3() {
      console.log(this.currentSelectedLesson);
    },
    async getLessonScoreAnalytics(item) {
      if (
        !item["year1UnelgeeScores"] ||
        (item["year1UnelgeeScores"] &&
          item["year1UnelgeeScores"].approvedNumber != item["numberOfStudents"])
      ) {
        console.log("running");
        var querySnapshot = await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(
            String(
              this.selectedProgram.STUDENT_GROUP_ID +
                "-" +
                item.courseInfo.COURSE_ID
            )
          )
          .collection("scores")
          .get();

        var approvedNumber = 0;
        var inProgressNumber = 0;
        var rejectedNumber = 0;
        var sentNumber = 0;
        var scoreAverage = 0;
        var loadingCounter = 0;
        // console.log(docs.size);
        // docs.forEach(async (doc) => {

        // });
        for (const doc of querySnapshot.docs) {
          let score = doc.data();
          score.ref = doc.ref;
          score.id = doc.id;
          var studentDoc = await this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .doc(String(score.PERSON_ID))
            .get();
          if (score.lessonGroupRef) {
            var lessonGroupStudentDoc = await score.lessonGroupRef
              .collection("students")
              .doc(String(score.PERSON_ID))
              .get();
            var lessonGroupDoc = await score.lessonGroupRef.get();
          } else {
            // console.log("no lessonGroupRef", score);
          }
          if (studentDoc.exists && studentDoc.data().moved != true) {
            if (item.esisLessonType.esisLessonTypeId > 1) {
              if (
                score.STUDENT_GROUP_ID ==
                  this.selectedProgram.STUDENT_GROUP_ID &&
                score.id.includes("-" + score.lessonGroupId)
              ) {
                if (score.lessonGroupRef) {
                  if (
                    lessonGroupDoc.exists &&
                    (lessonGroupDoc.data().allStudentsSelected ||
                      lessonGroupStudentDoc.exists)
                  ) {
                    if (score.approved == true) {
                      approvedNumber++;
                      scoreAverage = scoreAverage + score.score;
                    } else if (score.approved == false) {
                      rejectedNumber++;
                    } else if (
                      (score.approved == undefined || score.approved == null) &&
                      score.sentToManager == true
                    ) {
                      inProgressNumber++;
                    }
                    if (
                      score.sentToManager != true &&
                      score.score != null &&
                      score.score != undefined &&
                      typeof score.score == "number"
                    ) {
                      sentNumber++;
                    }
                  }
                } else {
                  if (score.approved == true) {
                    approvedNumber++;
                    scoreAverage = scoreAverage + score.score;
                  } else if (score.approved == false) {
                    rejectedNumber++;
                  } else if (
                    (score.approved == undefined || score.approved == null) &&
                    score.sentToManager == true
                  ) {
                    inProgressNumber++;
                  }
                  if (
                    score.sentToManager != true &&
                    score.score != null &&
                    score.score != undefined &&
                    typeof score.score == "number"
                  ) {
                    sentNumber++;
                  }
                }
              }
            } else {
              if (
                score.STUDENT_GROUP_ID == this.selectedProgram.STUDENT_GROUP_ID
              ) {
                if (score.approved == true) {
                  approvedNumber++;
                  scoreAverage = scoreAverage + score.score;
                } else if (score.approved == false) {
                  rejectedNumber++;
                } else if (
                  (score.approved == undefined || score.approved == null) &&
                  score.sentToManager == true
                ) {
                  inProgressNumber++;
                }
                if (
                  score.sentToManager != true &&
                  score.score != null &&
                  score.score != undefined &&
                  typeof score.score == "number"
                ) {
                  sentNumber++;
                }
              }
            }
          } else {
            console.log("moved student", score);
          }

          loadingCounter++;
          if (loadingCounter == querySnapshot.size) {
            scoreAverage = Number((scoreAverage / approvedNumber).toFixed(1));
            var year1UnelgeeScores = {
              approvedNumber: approvedNumber,
              inProgressNumber: inProgressNumber,
              rejectedNumber: rejectedNumber,
              sentNumber: sentNumber,
              scoreAverage: scoreAverage,
            };

            item.ref
              .update({
                year1UnelgeeScores: year1UnelgeeScores,
              })
              .then(() => {
                item.year1UnelgeeScores = year1UnelgeeScores;
                this.$forceUpdate();
              });
          }
        }
      }
    },
    async getClassStudentNumber(item) {
      if (this.filteredLessons && this.filteredLessons.length > 0) {
        if (item.esisLessonType && item.esisLessonType.esisLessonTypeId == 1) {
          this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .where(
              "classGroup-" + this.userData.school.currentYear,
              "==",
              item.classGroupRefs[0]
            )
            .orderBy("FIRST_NAME", "asc")
            .get()
            .then((docs) => {
              var numberOfStudents = 0;
              var loadingCounter = 0;
              docs.forEach((doc) => {
                if (doc.exists && doc.data().moved != true) {
                  numberOfStudents++;
                }
                loadingCounter++;
                if (loadingCounter == docs.size) {
                  if (
                    item.numberOfStudents == undefined ||
                    item.numberOfStudents != numberOfStudents
                  ) {
                    item.numberOfStudents = numberOfStudents;
                    this.$forceUpdate();

                    item.ref.update({ numberOfStudents: numberOfStudents });
                  }
                }
              });
            });
        } else if (
          item.esisLessonType &&
          item.esisLessonType.esisLessonTypeId > 1 &&
          item.subLessons &&
          item.subLessons.length > 0
        ) {
          var numberOfStudents = 0;
          var lgloadingCounter = 0;
          var addingStudents = [];
          item.subLessons.forEach((sll) => {
            sll.ref
              .collection("lesson-groups")
              .orderBy("groupIndex", "asc")
              .get()
              .then((docs) => {
                console.log(item);

                docs.forEach(async (doc) => {
                  await doc.ref
                    .collection("students")
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        if (
                          doc.data().STUDENT_GROUP_ID ==
                          this.selectedProgram.STUDENT_GROUP_ID
                        ) {
                          numberOfStudents++;
                          addingStudents.push(doc.data().PERSON_ID);
                        }
                      });
                      lgloadingCounter++;
                    });
                  if (lgloadingCounter == docs.size) {
                    addingStudents.forEach(async (studentId) => {
                      this.userData.school.ref
                        .collection(
                          "students-" + this.userData.school.currentYear
                        )
                        .doc(String(studentId))
                        .get()
                        .then((doc) => {
                          if (doc.exists == false || doc.data().moved == true) {
                            numberOfStudents2--;
                          }
                        });
                    });
                    if (
                      item.numberOfStudents == undefined ||
                      item.numberOfStudents != numberOfStudents
                    ) {
                      item.numberOfStudents = numberOfStudents;
                      this.$forceUpdate();

                      item.ref.update({ numberOfStudents: numberOfStudents });
                    }
                  }
                });
              });
          });
        } else {
          var numberOfStudents2 = 0;
          await item.ref
            .collection("lesson-groups")
            .orderBy("groupIndex", "asc")
            .get()
            .then((docs) => {
              var loadingCounter = 0;
              var addingStudents = [];
              docs.forEach(async (doc) => {
                if (doc.data().allStudentsSelected) {
                  if (
                    item.classGroupRefs[0].path == this.selectedProgram.ref.path
                  ) {
                    await this.userData.school.ref
                      .collection(
                        "students-" + this.userData.school.currentYear
                      )
                      .where(
                        "classGroup-" + this.userData.school.currentYear,
                        "==",
                        item.classGroupRefs[0]
                      )
                      .orderBy("FIRST_NAME", "asc")
                      .get()
                      .then((docs) => {
                        docs.forEach((doc) => {
                          if (doc.exists && doc.data().moved != true) {
                            numberOfStudents2++;
                          }
                        });
                        loadingCounter++;
                      });
                  } else {
                    loadingCounter++;
                  }
                } else {
                  await doc.ref
                    .collection("students")
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        if (
                          doc.data().STUDENT_GROUP_ID ==
                          this.selectedProgram.STUDENT_GROUP_ID
                        ) {
                          numberOfStudents2++;
                          addingStudents.push(doc.data().PERSON_ID);
                        }
                      });
                      loadingCounter++;
                    });
                }
                if (loadingCounter == docs.size) {
                  addingStudents.forEach(async (studentId) => {
                    this.userData.school.ref
                      .collection(
                        "students-" + this.userData.school.currentYear
                      )
                      .doc(String(studentId))
                      .get()
                      .then((doc) => {
                        if (doc.exists == false || doc.data().moved == true) {
                          numberOfStudents2--;
                        }
                      });
                  });

                  if (
                    item.numberOfStudents == undefined ||
                    item.numberOfStudents != numberOfStudents2
                  ) {
                    item.numberOfStudents = numberOfStudents2;
                    this.$forceUpdate();

                    item.ref.update({ numberOfStudents: numberOfStudents2 });
                  }
                }
              });
            });
        }
      }
    },
    async _doUnelgee(item) {
      this.currentSelectedLesson = item;
      this.currentSelectedLesson.loading2 = true;
      await this.userData.school.ref
        .collection("unelgee2-" + this.userData.school.currentYear + "-scores")
        .doc(String(this.currentSelectedLesson.id))
        .collection("scores")
        .where("sentToManager", "==", true)
        .orderBy("createdAt", "asc")
        .get()
        .then((docs) => {
          this.allScores = [];
          docs.forEach(async (doc) => {
            let score = doc.data();
            score.ref = doc.ref;
            score.id = doc.id;

            //remove
            await this.userData.school.ref
              .collection("students-" + this.userData.school.currentYear)
              .doc(String(score.PERSON_ID))
              .get()
              .then((doc) => {
                if (doc.exists) {
                  let studentData = doc.data();
                  studentData.ref = doc.ref;
                  studentData.id = doc.id;
                  score.studentData = studentData;
                } else {
                  console.log(score);
                }
              });
            if (score.studentData) {
              console.log(score.courseInfo.GRADING_SCHEME_ID);
              // score
              // scoreFinal
              // scoreLevelNumber
              // scoreLevelString
              if (score.courseInfo.GRADING_SCHEME_ID == 13) {
                if (score.scoreFinal > 0) score.scoreLevelString = "Тооцсон";
                else score.scoreLevelString = "Тооцоогүй";
              }

              this.allScores.push(score);
            }
          });
        });
      //reading unelgee1 scores
      await this.userData.school.ref
        .collection("courses-" + this.userData.school.currentYear)
        .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString())
        .collection("unelgee1-" + this.userData.school.currentYear + "-scores")
        .where("STUDENT_GROUP_ID", "==", this.selectedProgram.STUDENT_GROUP_ID)
        .get()
        .then((querySnapshot) => {
          this.unelgee1Scores = [];
          querySnapshot.forEach(async (doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            await item.examRef.get().then((doc) => {
              if (doc.exists && doc.data().deleted != true) {
                item.examDate = new Date(doc.data().examDate);
                item.deleted = false;
              } else {
                item.deleted = true;
              }
            });
            if (item.deleted == false) {
              this.unelgee1Scores.push(item);
            }
          });
        });

      if (this.currentSelectedLesson) {
        if (
          this.currentSelectedLesson.esisLessonType &&
          this.currentSelectedLesson.esisLessonType.esisLessonTypeId == 1
        ) {
          this.loading = true;
          console.log(this.currentSelectedLesson.classGroupRefs[0].id);
          this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .where(
              "STUDENT_GROUP_ID",
              "==",
              Number(this.currentSelectedLesson.classGroupRefs[0].id)
            )
            .orderBy("FIRST_NAME", "asc")
            .get()
            .then((docs) => {
              console.log(docs.size);
              this.students = [];
              docs.forEach((doc) => {
                let student = doc.data();
                student.ref = doc.ref;
                student.id = doc.id;
                let unelgee2 = this.allScores.find(
                  (sss) => sss.PERSON_ID == student.PERSON_ID
                );
                if (unelgee2) {
                  student.score =
                    unelgee2.score != null &&
                    unelgee2.score != undefined &&
                    typeof unelgee2.score == "number"
                      ? unelgee2.score
                      : null;
                  student.scoreFinal = unelgee2.scoreFinal
                    ? unelgee2.scoreFinal
                    : null;
                  student.scoreLevelString = unelgee2.scoreLevelString
                    ? unelgee2.scoreLevelString
                    : null;
                  student.scoreLevelNumber = unelgee2.scoreLevelNumber
                    ? unelgee2.scoreLevelNumber
                    : null;
                }

                if (!student.moved) {
                  this.students.push(student);
                }
              });
              this.loading = false;
              this.forceRerender();
              this.unelgeeDialog = !this.unelgeeDialog;
            });
        } else if (
          this.currentSelectedLesson.esisLessonType &&
          this.currentSelectedLesson.esisLessonType.esisLessonTypeId > 1 &&
          this.currentSelectedLesson.subLessons &&
          this.currentSelectedLesson.subLessons.length > 0
        ) {
          this.students = [];
          for (const sll of this.currentSelectedLesson.subLessons) {
            this.loading = true;
            sll.ref
              .collection("lesson-groups")
              .orderBy("groupIndex", "asc")
              .get()
              .then((docs) => {
                console.log(docs.size, this.selectedProgram);
                docs.forEach(async (doc) => {
                  let gg = doc.data();

                  if (doc.exists) {
                    gg.id = doc.id;
                    gg.ref = doc.ref;
                    gg.students = null;
                    gg.students = [];
                    await gg.ref
                      .collection("students")
                      .orderBy("FIRST_NAME", "asc")
                      .get()
                      .then((docs) => {
                        docs.forEach(async (doc) => {
                          let student = doc.data();
                          student.id = doc.id;
                          student.ref = doc.ref;

                          var allowed = false;
                          await this.userData.school.ref
                            .collection(
                              "students-" + this.userData.school.currentYear
                            )
                            .doc(String(student.PERSON_ID))
                            .get()
                            .then((doc) => {
                              if (doc.exists && doc.data().moved != true) {
                                allowed = true;
                              }
                            });
                          if (
                            this.selectedProgram.STUDENT_GROUP_ID ==
                              student.STUDENT_GROUP_ID &&
                            allowed
                          ) {
                            student.classGroup = sll.classGroups[0];
                            student.classGroupRef =
                              sll.classGroups[0].classGroupRef;
                            student.lessonGroup = gg;
                            student.attendances = null;
                            student.studentsNotes = null;

                            let unelgee2 = this.allScores.find(
                              (sss) => sss.PERSON_ID == student.PERSON_ID
                            );
                            if (unelgee2) {
                              student.score =
                                unelgee2.score != null &&
                                unelgee2.score != undefined &&
                                typeof unelgee2.score == "number"
                                  ? unelgee2.score
                                  : null;
                              student.scoreFinal = unelgee2.scoreFinal
                                ? unelgee2.scoreFinal
                                : null;
                              student.scoreLevelString =
                                unelgee2.scoreLevelString
                                  ? unelgee2.scoreLevelString
                                  : null;
                              student.scoreLevelNumber =
                                unelgee2.scoreLevelNumber
                                  ? unelgee2.scoreLevelNumber
                                  : null;
                            }

                            gg.students.push(student);
                            // if (
                            //   this.students.find(
                            //     (stud) => stud.PERSON_ID == student.PERSON_ID
                            //   ) == undefined
                            // ) {
                            this.students.push(student);
                            // }
                          }
                        });
                      });
                  }
                });
              });
          }
          this.loading = false;
          this.forceRerender();
          this.unelgeeDialog = !this.unelgeeDialog;
        } else {
          this.loading = true;
          this.currentSelectedLesson.lessonGroups = null;
          this.students = [];
          this.currentSelectedLesson.isGroupBased = true;
          this.currentSelectedLesson.ref
            .collection("lesson-groups")
            .orderBy("groupIndex", "asc")
            .get()
            .then((docs) => {
              this.currentSelectedLesson.lessonGroups = [];
              docs.forEach(async (doc) => {
                if (doc.exists) {
                  let gg = doc.data();
                  gg.id = doc.id;
                  gg.ref = doc.ref;
                  gg.students = [];
                  if (gg.allStudentsSelected) {
                    this.userData.school.ref
                      .collection(
                        "students-" + this.userData.school.currentYear
                      )
                      .where(
                        "STUDENT_GROUP_ID",
                        "==",
                        Number(this.currentSelectedLesson.classGroupRefs[0].id)
                      )
                      .orderBy("FIRST_NAME", "asc")
                      .get()
                      .then((docs) => {
                        docs.forEach((doc) => {
                          let student = doc.data();
                          student.ref = doc.ref;
                          student.id = doc.id;
                          student.lessonGroup = gg;
                          // console.log(student);
                          let unelgee2 = this.allScores.find(
                            (sss) => sss.PERSON_ID == student.PERSON_ID
                          );
                          if (unelgee2) {
                            student.score =
                              unelgee2.score != null &&
                              unelgee2.score != undefined &&
                              typeof unelgee2.score == "number"
                                ? unelgee2.score
                                : null;
                            student.scoreFinal = unelgee2.scoreFinal
                              ? unelgee2.scoreFinal
                              : null;
                            student.scoreLevelString = unelgee2.scoreLevelString
                              ? unelgee2.scoreLevelString
                              : null;
                            student.scoreLevelNumber = unelgee2.scoreLevelNumber
                              ? unelgee2.scoreLevelNumber
                              : null;
                          }

                          // if (
                          //   this.students.find(
                          //     (stud) => stud.PERSON_ID == student.PERSON_ID
                          //   ) == undefined
                          // ) {
                          if (!student.moved) {
                            gg.students.push(student);
                            this.students.push(student);
                          }
                          // }
                        });
                      });
                  } else {
                    var zz = await gg.ref
                      .collection("students")
                      .orderBy("FIRST_NAME", "asc")
                      .get();
                    zz.docs.forEach(async (doc) => {
                      let student = doc.data();
                      student.ref = doc.ref;
                      student.id = doc.id;
                      var allowed = false;
                      await this.userData.school.ref
                        .collection(
                          "students-" + this.userData.school.currentYear
                        )
                        .doc(String(student.PERSON_ID))
                        .get()
                        .then((doc) => {
                          if (doc.exists && doc.data().moved != true) {
                            allowed = true;
                          }
                        });
                      if (allowed) {
                        if (
                          this.selectedProgram.STUDENT_GROUP_ID ==
                          student.STUDENT_GROUP_ID
                        ) {
                          student.lessonGroup = gg;

                          let unelgee2 = this.allScores.find(
                            (sss) => sss.PERSON_ID == student.PERSON_ID
                          );
                          if (unelgee2) {
                            student.score =
                              unelgee2.score != null &&
                              unelgee2.score != undefined &&
                              typeof unelgee2.score == "number"
                                ? unelgee2.score
                                : null;
                            student.scoreFinal = unelgee2.scoreFinal
                              ? unelgee2.scoreFinal
                              : null;
                            student.scoreLevelString = unelgee2.scoreLevelString
                              ? unelgee2.scoreLevelString
                              : null;
                            student.scoreLevelNumber = unelgee2.scoreLevelNumber
                              ? unelgee2.scoreLevelNumber
                              : null;
                          }

                          gg.students.push(student);
                          // if (
                          //   this.students.find(
                          //     (stud) => stud.PERSON_ID == student.PERSON_ID
                          //   ) == undefined
                          // ) {
                          this.students.push(student);
                          // }
                        }
                      }
                    });
                  }
                  this.currentSelectedLesson.lessonGroups.push(gg);
                }
              });
              this.loading = false;
              this.forceRerender();
              this.unelgeeDialog = !this.unelgeeDialog;
            });
        }
      }
    },
    _saveUnelgee2() {
      var batch = fb.db.batch();
      this.savingUnelgee = true;
      var scoreLevelStrings = {
        _I: 0,
        _II: 0,
        _III: 0,
        _IV: 0,
        _V: 0,
        _VI: 0,
        _VII: 0,
        _VIII: 0,
      };
      var scoreLevelStrings2 = {
        passed: 0,
        failed: 0,
      };
      var allCompleted = true;

      for (var score of this.allScores) {
        if (
          score.scoreFinal != undefined &&
          score.scoreFinal != null &&
          typeof score.scoreFinal == "number" &&
          score.approved != null
        ) {
          score["approvedAt"] = new Date();
          score["approvedByRef"] = this.userData.ref;
          score["approvedByEmail"] = this.userData.email;
          if (score.approved == false) {
            score["rejected"] = true;
          }

          batch.update(score.ref, score);

          if (score.approved == true) {
            if (
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 14
            ) {
              if (score.scoreFinal > 0) {
                scoreLevelStrings2["passed"]++;
              } else {
                scoreLevelStrings2["failed"]++;
              }
            } else {
              if (score.scoreLevelNumber == 1) {
                scoreLevelStrings["_I"]++;
              } else if (score.scoreLevelNumber == 2) {
                scoreLevelStrings["_II"]++;
              } else if (score.scoreLevelNumber == 3) {
                scoreLevelStrings["_III"]++;
              } else if (score.scoreLevelNumber == 4) {
                scoreLevelStrings["_IV"]++;
              } else if (score.scoreLevelNumber == 5) {
                scoreLevelStrings["_V"]++;
              } else if (score.scoreLevelNumber == 6) {
                scoreLevelStrings["_VI"]++;
              } else if (score.scoreLevelNumber == 7) {
                scoreLevelStrings["_VII"]++;
              } else if (score.scoreLevelNumber == 8) {
                scoreLevelStrings["_VIII"]++;
              }
            }
          } else {
            allCompleted = false;
          }
        }
      }

      var tmp = {};
      tmp["year1UnelgeeScores"] = {
        approvedNumber: 0,
        inProgressNumber: 0,
        rejectedNumber: 0,
        sentNumber: 0,
        scoreAverage: 0,
      };
      this.currentSelectedLesson.byTeachers.forEach((teacher) => {
        tmp["year1UnelgeeScores-" + teacher.teacherId] = {
          approvedNumber: 0,
          inProgressNumber: 0,
          rejectedNumber: 0,
          sentNumber: 0,
          scoreAverage: 0,
        };
      });
      // go through students and finalizing the inProgress, sentNumber, approvedNumber, etc.
      for (var student of this.students) {
        if (this.currentSelectedLesson.esisLessonType.esisLessonTypeId > 1) {
          var studentScore = this.allScores.find(
            (studentScore) =>
              studentScore.PERSON_ID == student.PERSON_ID &&
              studentScore.lessonGroupId == student.lessonGroup.id
          );
        } else {
          studentScore = this.allScores.find(
            (studentScore) => studentScore.PERSON_ID == student.PERSON_ID
          );
        }
        if (
          studentScore &&
          this.currentSelectedLesson.byTeachers.find(
            (teacher) => teacher.teacherRef == studentScore.gradedBy.ref
          ) == undefined
        ) {
          if (this.currentSelectedLesson.byTeachers.length == 1) {
            studentScore.gradedBy.PERSON_ID =
              this.currentSelectedLesson.byTeachers[0].teacherId;
          } else if (this.currentSelectedLesson.byTeachers.length > 1) {
            if (!tmp["year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID]) {
              tmp["year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID] = {
                approvedNumber: 0,
                inProgressNumber: 0,
                rejectedNumber: 0,
                sentNumber: 0,
                scoreAverage: 0,
              };
            }
          }
        }
        if (studentScore) {
          //if score is approved by manager, we add them
          if (studentScore.approved == true) {
            tmp["year1UnelgeeScores"].approvedNumber++;
            tmp["year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID]
              .approvedNumber++;
            if (
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 14
            ) {
              if (studentScore.score > 0) {
                tmp["year1UnelgeeScores"].scoreAverage =
                  tmp["year1UnelgeeScores"].scoreAverage + 100;
                tmp[
                  "year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID
                ].scoreAverage =
                  tmp["year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID]
                    .scoreAverage + 100;
              } else {
                tmp["year1UnelgeeScores"].scoreAverage =
                  tmp["year1UnelgeeScores"].scoreAverage + studentScore.score;
                tmp[
                  "year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID
                ].scoreAverage =
                  tmp["year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID]
                    .scoreAverage + studentScore.score;
              }
            } else {
              //baga angias deesh dungeer toozdog bol
              tmp["year1UnelgeeScores"].scoreAverage =
                tmp["year1UnelgeeScores"].scoreAverage + studentScore.score;
              tmp[
                "year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID
              ].scoreAverage =
                tmp["year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID]
                  .scoreAverage + studentScore.score;
            }
          } else if (studentScore.approved == false) {
            tmp["year1UnelgeeScores"].rejectedNumber++;
            tmp["year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID]
              .rejectedNumber++;
          } else if (
            (studentScore.approved == undefined ||
              studentScore.approved == null) &&
            studentScore.sentToManager == true
          ) {
            tmp["year1UnelgeeScores"].inProgressNumber++;
            tmp["year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID]
              .inProgressNumber++;
          }
          if (
            studentScore.sentToManager != true &&
            studentScore.score != null &&
            studentScore.score != undefined &&
            typeof studentScore.score == "number"
          ) {
            tmp["year1UnelgeeScores"].sentNumber++;
            tmp["year1UnelgeeScores-" + studentScore.gradedBy.PERSON_ID]
              .sentNumber++;
          }
        }
      }

      tmp["year1UnelgeeScores"].scoreAverage = Number(
        (
          tmp["year1UnelgeeScores"].scoreAverage /
          tmp["year1UnelgeeScores"].approvedNumber
        ).toFixed(1)
      );

      this.currentSelectedLesson.byTeachers.forEach((teacher) => {
        tmp["year1UnelgeeScores-" + teacher.teacherId].scoreAverage = Number(
          (
            tmp["year1UnelgeeScores-" + teacher.teacherId].scoreAverage /
            tmp["year1UnelgeeScores-" + teacher.teacherId].approvedNumber
          ).toFixed(1)
        );
      });

      batch.set(this.currentSelectedLesson.ref, tmp, {
        merge: true,
      });
      if (
        this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
        this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 14
      ) {
        batch.set(
          this.userData.school.ref
            .collection(
              "unelgee2-" + this.userData.school.currentYear + "-scores"
            )
            .doc(this.currentSelectedLesson.id),
          {
            _schoolId: this.userData.school.id,
            _ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
            _completed100: allCompleted,
            _COURSE_ID: this.currentSelectedLesson.courseInfo.COURSE_ID,
            passed: scoreLevelStrings2["passed"],
            failed: scoreLevelStrings2["failed"],
          },
          { merge: true }
        );
      } else {
        batch.set(
          this.userData.school.ref
            .collection(
              "unelgee2-" + this.userData.school.currentYear + "-scores"
            )
            .doc(this.currentSelectedLesson.id),
          {
            _schoolId: this.userData.school.id,
            _ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
            _completed100: allCompleted,
            _COURSE_ID: this.currentSelectedLesson.courseInfo.COURSE_ID,
            _I: scoreLevelStrings["_I"],
            _II: scoreLevelStrings["_II"],
            _III: scoreLevelStrings["_III"],
            _IV: scoreLevelStrings["_IV"],
            _V: scoreLevelStrings["_V"],
            _VI: scoreLevelStrings["_VI"],
            _VII: scoreLevelStrings["_VII"],
            _VIII: scoreLevelStrings["_VIII"],
          },
          { merge: true }
        );
      }
      batch.commit().then(() => {
        this.savingUnelgee = false;
        var lesson = this.lessons.find(
          (lesson) => lesson.id == this.currentSelectedLesson.id
        );
        lesson["year1UnelgeeScores"] = tmp["year1UnelgeeScores"];
        this.$forceUpdate();
        this._closeUnelgeeDialog();
        this.$swal.fire("Амжилттай xадгалагдсан!");
      });
    },
    _closeUnelgeeDialog() {
      this.currentSelectedLesson.loading2 = false;
      this.unelgeeDialog = !this.unelgeeDialog;
    },
    _print2() {
      console.log(this.selectedProgram.ref.path);
    },
    async readPlan() {
      await this.school.ref
        .collection("lessons-" + this.school.currentYear)
        .where("classGroupRefs", "array-contains", this.selectedProgram.ref)
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .onSnapshot((docs) => {
          this.lessons = [];
          console.log(docs.size);
          docs.forEach((doc) => {
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;

            if (lesson.byTeachers && lesson.byTeachers.length > 0) {
              lesson.lessonGroups = null;
              lesson.ref
                .collection("lesson-groups")
                .orderBy("groupIndex")
                .get()
                .then(async (docs) => {
                  lesson.lessonGroups = [];
                  docs.forEach(async (lgroup) => {
                    let ll = lgroup.data();
                    ll.id = lgroup.id;
                    ll.ref = lgroup.ref;
                    ll.ref
                      .collection("students")
                      .orderBy("FIRST_NAME", "asc")
                      .onSnapshot((docs) => {
                        ll.students = [];
                        var counter = 0;
                        docs.forEach((doc) => {
                          counter++;
                          let gstudent = doc.data();
                          gstudent.id = doc.id;
                          gstudent.ref = doc.ref;
                          gstudent.index = counter;
                          ll.students.push(gstudent);
                        });

                        this.$forceUpdate();
                      });

                    lesson.lessonGroups.push(ll);
                  });
                  if (!lesson.numberOfStudents)
                    await this.getClassStudentNumber(lesson);
                  await this.getLessonScoreAnalytics(lesson);
                });
              this.lessons.push(lesson);
            }
          });
          this.lessons = this.lessons.sort(
            (a, b) =>
              b.courseInfo.ENROLLMENT_CATEGORY.length -
              a.courseInfo.ENROLLMENT_CATEGORY.length
          );
        });
      // setTimeout(() => {
      //   if (this.lessons.length == 0) {
      //     this.school.ref
      //       .collection("lessons-" + this.school.currentYear)
      //       .where(
      //         "classGroupRefs",
      //         "array-contains",
      //         fb.db.doc(this.selectedProgram.ref.path.replace("2023", "2022"))
      //       )
      //       .where("deleted", "==", false)
      //       .orderBy("courseInfo.COURSE_NAME", "asc")
      //       .onSnapshot((docs) => {
      //         this.lessons = [];
      //         docs.forEach((doc) => {
      //           let lesson = doc.data();
      //           lesson.id = doc.id;
      //           lesson.ref = doc.ref;

      //           lesson.classGroupRefs[0] = fb.db.doc(
      //             lesson.classGroupRefs[0].path.replace(
      //               "2022",
      //               this.userData.school.currentYear
      //             )
      //           );

      //           if (lesson.byTeachers && lesson.byTeachers.length > 0) {
      //             lesson.lessonGroups = null;
      //             lesson.ref
      //               .collection("lesson-groups")
      //               .orderBy("groupIndex")
      //               .get()
      //               .then(async (docs) => {
      //                 lesson.lessonGroups = [];
      //                 docs.forEach(async (lgroup) => {
      //                   let ll = lgroup.data();
      //                   ll.id = lgroup.id;
      //                   ll.ref = lgroup.ref;
      //                   ll.ref
      //                     .collection("students")
      //                     .orderBy("FIRST_NAME", "asc")
      //                     .onSnapshot((docs) => {
      //                       ll.students = [];
      //                       var counter = 0;
      //                       docs.forEach((doc) => {
      //                         counter++;
      //                         let gstudent = doc.data();
      //                         gstudent.id = doc.id;
      //                         gstudent.ref = doc.ref;
      //                         gstudent.index = counter;
      //                         ll.students.push(gstudent);
      //                       });

      //                       this.$forceUpdate();
      //                     });

      //                   lesson.lessonGroups.push(ll);
      //                 });
      //                 if (!lesson.numberOfStudents)
      //                   await this.getClassStudentNumber(lesson);
      //                 await this.getLessonScoreAnalytics(lesson);
      //               });
      //             this.lessons.push(lesson);
      //           }
      //         });
      //         this.lessons = this.lessons.sort(
      //           (a, b) =>
      //             b.courseInfo.ENROLLMENT_CATEGORY.length -
      //             a.courseInfo.ENROLLMENT_CATEGORY.length
      //         );
      //       });
      //   }
      // }, 1000);
    },
    _print(item) {
      console.log(item, item.ref.path);
      console.log(this.students.length);
    },
    _getMyClassInfo(value) {
      if (this.zClassGroup) return this.zClassGroup[value];
      else return null;
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>
